import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Admin/Report/Report.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login/Login.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/SignUp/SignUp.vue"),
  },
  {
    path: "/add-booking",
    name: "addBooking",
    component: () => import("../views/AddBooking/AddBooking.vue"),
  },
  {
    path: "/edit-booking/:id",
    name: "editBooking",
    component: () => import("../views/EditBooking/EditBooking.vue"),
  },
  // Admin
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/Admin/Admin.vue"),
    redirect: "/admin/car-setting",
    children: [
      {
        path: "schedule-detail/:id",
        name: "adminScheduleDetail",
        component: () =>
          import("../views/Admin/ScheduleDetail/ScheduleDetail.vue"),
      },
      {
        path: "report",
        name: "adminReport",
        component: () => import("../views/Admin/Report/Report.vue"),
      },
      {
        path: "sms",
        name: "adminSms",
        component: () => import("../views/Admin/Sms/Sms.vue"),
      },
      {
        path: "car-setting",
        name: "adminCarSetting",
        component: () => import("../views/Admin/CarSetting/CarSetting.vue"),
      },
      {
        path: "departure-list",
        name: "adminDepartureList",
        component: () =>
          import("../views/Admin/DepartureList/DepartureList.vue"),
      },
    ],
  },
  // Driver
  {
    path: "/driver",
    name: "driver",
    component: () => import("../views/Driver/Driver.vue"),
    redirect: "/driver/dashboard",
    children: [
      {
        path: "dashboard",
        name: "driverDashboard",
        component: () => import("../views/Driver/Dashboard/Dashboard.vue"),
        meta: { transition: "slide-left" },
      },
      {
        path: "report",
        name: "driverReport",
        component: () => import("../views/Driver/Report/Report.vue"),
      },
      {
        path: "booking-list",
        name: "driverBookingList",
        component: () => import("../views/Driver/BookingList/BookingList.vue"),
      },
      {
        path: "schedule-detail/:id",
        name: "driverScheduleDetail",
        component: () =>
          import("../views/Driver/ScheduleDetail/ScheduleDetail.vue"),
      },
      {
        path: "profile",
        name: "driverProfile",
        component: () => import("../views/Driver/Profile/Profile.vue"),
      },
    ],
  },
  // Member
  {
    path: "/member",
    name: "member",
    component: () => import("../views/Member/Member.vue"),
    redirect: "/member/dashboard",
    children: [
      {
        path: "dashboard",
        name: "memberDashboard",
        component: () => import("../views/Member/Dashboard/Dashboard.vue"),
      },
      {
        path: "profile",
        name: "memberProfile",
        component: () => import("../views/Member/Profile/Profile.vue"),
      },
      {
        path: "history",
        name: "memberHistory",
        component: () => import("../views/Member/History/History.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = localStorage.getItem("userData") || false;

  // Redirect to login if not logged in
  if (!isLoggedIn && to.name != "login" && to.name != "signup") {
    next({ name: "login" });
  }
  // else if (!isLoggedIn && to.name == "signup") {
  //   next({ name: "signup" });
  // }
  else if (isLoggedIn && to.name == "login") {
    next({ name: "driverDashboard" });
  } else {
    next();
  }
});
