<template>
  <div id="app">
    <vs-navbar fixed shadow class="nav-top" v-if="isLoged">
      <template #left>
        <h6 @click="logout()" style="display: flex; align-items: center">
          <i class="bx bx-log-out" style="padding-top: 2px"></i> &nbsp; Thoát
        </h6>
      </template>
      <template #right>
        <h6>Xin chào, {{ userData.nicename }}</h6>
      </template>
    </vs-navbar>
    <main
      class="container-fluid"
      :style="isLoged ? 'margin-top: 64px; margin-bottom: 80px;' : ''"
    >
      <transition name="slide-fade">
        <router-view class="view" />
      </transition>
    </main>
    <vs-navbar
      fixed
      v-if="isLoged"
      class="nav-bottom"
      text-white
      square
      color="primary"
    >
      <vs-navbar-item
        v-if="checkRole(['customer', 'driver'])"
        :to="getUrlHome()"
        :active="getUrlHome() == $router.currentRoute.path ? true : false"
      >
        <div>
          <i class="bx bx-home"></i>
          <div>Trang chủ</div>
        </div>
      </vs-navbar-item>

      <vs-navbar-item
        v-if="checkRole(['administrator'])"
        to="/admin/sms"
        :active="'/admin/sms' == $router.currentRoute.path ? true : false"
      >
        <div>
          <i class="bx bx-message-dots"></i>
          <div>SMS</div>
        </div>
      </vs-navbar-item>

      <vs-navbar-item
        :to="getUrlHistory()"
        :active="getUrlHistory() == $router.currentRoute.path ? true : false"
      >
        <i class="bx bx-history"></i>
        <div>Lịch sử</div>
      </vs-navbar-item>
      <vs-navbar-item
        to="/add-booking"
        :active="'/add-booking' == $router.currentRoute.path ? true : false"
      >
        <i class="bx bx-plus"></i>
        <div>Đặt ghế</div>
      </vs-navbar-item>
      <vs-navbar-item
        v-if="checkRole(['driver', 'administrator'])"
        :to="getUrlReport()"
        :active="getUrlReport() == $router.currentRoute.path ? true : false"
      >
        <i class="bx bx-bar-chart-alt"></i>
        <div>Báo cáo</div>
      </vs-navbar-item>
      <vs-navbar-item
        :to="getUrlProfile()"
        :active="getUrlProfile() == $router.currentRoute.path ? true : false"
      >
        <i class="bx bx-cog"></i>
        <div>Tài khoản</div>
      </vs-navbar-item>
    </vs-navbar>
  </div>
</template>
<script>
import Vue from "vue";
import EventBus from "@/EventBus";
export default {
  components: {},
  data: () => ({
    userData: JSON.parse(localStorage.getItem("userData")) || false,
    isLoged: JSON.parse(localStorage.getItem("userData")) || false,
  }),
  watch: {},
  created() {
    Vue.prototype.$vs = this.$vs;
    EventBus.$on("afterLogin", this.afterLogin);
  },
  methods: {
    afterLogin(e) {
      this.isLoged = e;
      this.userData = JSON.parse(localStorage.getItem("userData"));
    },
    checkRole(role) {
      let userRole = this.userData.roles;
      if (userRole) {
        var duplicates = role.filter(function (val) {
          return userRole.indexOf(val) != -1;
        });
        return duplicates.length;
      } else {
        return false;
      }
    },
    logout() {
      localStorage.removeItem("userData");
      this.isLoged = false;
      this.$router.push("/login");
    },
    getUrlHome() {
      if (this.checkRole(["customer"])) {
        return "/member/dashboard";
      }
      if (this.checkRole(["driver"])) {
        return "/driver/dashboard";
      }
      if (this.checkRole(["administrator"])) {
        return "/admin/dashboard";
      }
    },
    getUrlProfile() {
      if (this.checkRole(["customer"])) {
        return "/member/profile";
      }
      if (this.checkRole(["driver", "administrator"])) {
        return "/driver/profile";
      }
    },
    getUrlHistory() {
      if (this.checkRole(["customer"])) {
        return "/member/history";
      }
      if (this.checkRole(["driver"])) {
        return "/driver/booking-list";
      }
    },
    getUrlReport() {
      if (this.checkRole(["driver"])) {
        return "/driver/report";
      }
      if (this.checkRole(["administrator"])) {
        return "/admin/report";
      }
    },
  },
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
// .slide-fade-leave-active {
//   transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
// }
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.vs-navbar-content {
  padding: 10px 0 !important;
}
.vs-sidebar-content.absolute {
  z-index: 99999999;
}

.nav-top {
  padding: 0 !important;
  .vs-navbar {
    padding: 0 9px;
  }
}
.nav-bottom {
  bottom: 0 !important;
  top: inherit !important;
  .vs-navbar {
    padding: 0;
    .vs-navbar__left,
    .vs-navbar__right {
      display: none;
    }
    .vs-navbar__center {
      width: 100%;
      justify-content: space-between;
      .vs-navbar__item {
        padding: 5px 9px;
        line-height: 18px;
        font-weight: 600;
        font-size: 14px;
        i {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
